import * as React from 'react';
import { ROLES } from '@bondvet/roles';
import {
    createMainLayout,
    MainProps,
} from '_embedded_packages/web-app-layout/layouts/createMainLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useTeamDataTabsEnabled from 'common/hooks/useTeamDataTabsEnabled';

const OPTIONAL_TABS = [
    {
        path: 'region-data',
        roles: [ROLES.regionData],
    },
    {
        path: 'clinic-data',
        roles: [ROLES.clinicData],
    },
];

const TABS = [
    {
        path: 'control-center',
        children: ['company-control-center', 'clinic-control-center'],
        roles: [ROLES.admin],
    },
    { path: 'admin', roles: [ROLES.admin] },
    { path: 'scheduling', roles: [ROLES.scheduling] },
    {
        path: 'ukg-scheduling',
        roles: [ROLES.ukgScheduling],
    },
];

const createLayout = (enabled: boolean) => {
    return createMainLayout(
        [...(enabled ? OPTIONAL_TABS : []), ...TABS],
        [{ path: 'account', icon: <AccountCircleIcon /> }],
    );
};

export const RightDrawerContext = React.createContext({
    drawerOpen: false,
    toggleDrawer: () => {},
});

export const LayoutWithRightDrawer: React.FC<MainProps> = (props) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const { enabled } = useTeamDataTabsEnabled();

    const toggleDrawer = React.useCallback(() => {
        setDrawerOpen((prevOpen) => !prevOpen);
    }, []);

    const Main = React.useMemo(() => createLayout(enabled), [enabled]);

    return (
        <RightDrawerContext.Provider value={{ drawerOpen, toggleDrawer }}>
            <Main
                {...props}
                hasRightDrawer
                rightDrawerOpen={drawerOpen}
                toggleRightDrawer={toggleDrawer}
            />
        </RightDrawerContext.Provider>
    );
};

export function withLayout(
    WrappedComponent: React.ComponentType<unknown>,
    Layout?: React.ComponentType,
) {
    return function WrapperComponent(props: any) {
        const { enabled } = useTeamDataTabsEnabled();

        const MainLayout = React.useMemo(() => {
            if (Layout) {
                return Layout;
            }
            return createLayout(enabled);
        }, [enabled]);

        return (
            <MainLayout homePath="/home">
                <WrappedComponent {...props} />
            </MainLayout>
        );
    };
}
