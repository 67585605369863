import { withLayout } from 'core/layouts';
import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';

export const NotFoundPage = Loadable({
    componentName: 'NotFound',
    loader: () => import(/* webpackPrefetch: true */ './NotFound'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    reducerName: 'notFound',
    loading: Loading,
});

export default () => [
    { exact: false, path: `*`, component: withLayout(NotFoundPage) },
];
