import { gql } from '@apollo/client';
import useAnalyticsQuery from './useAnalyticsQuery';

const teamAppDataTabsEnabled = gql`
    query teamAppDataTabsEnabled {
        teamAppDataTabsEnabled
    }
`;

type TeamAppDataTabsEnabledResponse = {
    teamAppDataTabsEnabled: boolean;
};

type UseTeamDataTabsEnabled = {
    loading: boolean;
    enabled: boolean;
    refetch: () => void;
};

export default function useTeamDataTabsEnabled(): UseTeamDataTabsEnabled {
    const { data, loading, refetch } =
        useAnalyticsQuery<TeamAppDataTabsEnabledResponse>(
            teamAppDataTabsEnabled,
        );

    return {
        loading,
        enabled: data?.teamAppDataTabsEnabled ?? false,
        refetch,
    };
}
