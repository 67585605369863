import { gql } from '@apollo/client';
import { UKGEmployee } from '@bondvet/types/ukg';
import {
    ProviderPaymentMonth,
    ProviderWithPaymentMonths,
} from '@bondvet/types/providers';
import { StaffUser } from '@bondvet/types/users';
import type { UKGProPerson } from '@bondvet/types/lib/ukgPro';
import {
    ClientFragment,
    UKGEmployeeFragment,
    UserFragment,
    UserFragmentType,
    VetspireProviderFragment,
    VetspireProviderFragmentType,
} from './fragments';

export interface UsersQueryBaseVariables {
    search: string;
    skip: number;
    limit: number;
    searchEmailOnly?: boolean;
}

export interface UsersQueryVariables extends UsersQueryBaseVariables {}

export interface UsersQueryResult {
    users: ReadonlyArray<UserFragmentType>;
}

export const users = gql`
    query users(
        $search: String
        $skip: Int
        $limit: Int
        $searchEmailOnly: Boolean!
    ) {
        users(
            search: $search
            limit: $limit
            skip: $skip
            searchEmailOnly: $searchEmailOnly
        ) {
            users {
                ...UserFragment
            }
            total
        }
    }
    ${UserFragment}
`;

export const staffUsers = gql`
    query staffUsers {
        staffUsers {
            _id
            vetspireId
            firstName
            lastName
            email
            locationIds
            regionIds
            roles
        }
    }
`;

export interface StaffUsersQueryResult {
    staffUsers: ReadonlyArray<StaffUser>;
}

export const getUserForEmailQuery = gql`
    query getUserForEmail($email: String!) {
        getUserForEmail(email: $email) {
            _id
            vetspireId
            firstName
            lastName
            email
            locationIds
            regionIds
            roles
        }
    }
`;

export interface GetUserForEmailQueryResult {
    getUserForEmail: StaffUser | null;
}

export interface GetUserForEmailQueryVariables {
    email: string;
}

export interface VetspireProvidersQuery {
    search: string;
    skip: number;
    limit: number;
}

export interface VetspireProvidersQueryResult {
    vetspireProviders: {
        providers: ReadonlyArray<VetspireProviderFragmentType>;
    };
    total: number;
}

export const vetspireProviders = gql`
    query vetspireProviders($search: String, $skip: Int, $limit: Int) {
        vetspireProviders(search: $search, limit: $limit, skip: $skip) {
            providers {
                ...VetspireProviderFragment
            }
            total
        }
    }
    ${VetspireProviderFragment}
`;

export const client = gql`
    ${ClientFragment}
    query client($id: ID!) {
        client(id: $id) {
            ...ClientFragment
        }
    }
`;

export interface VetspireUrlQueryResult {
    vetspireUrl: string;
}

export const vetspireUrl = gql`
    query vetspireUrl {
        vetspireUrl
    }
`;

export interface SimilarClientsQueryVariables {
    email: string;
    vetspireId?: string;
}

export interface SimilarClientsQueryResult {
    similarClients: ReadonlyArray<{
        id: string;
        givenName: string;
        familyName: string;
        patients: ReadonlyArray<{ name: string; species: string }>;
    }>;
}

export const similarClients = gql`
    query similarClients($email: Email!, $vetspireId: VetspireId) {
        similarClients(email: $email, vetspireId: $vetspireId) {
            id
            givenName
            familyName
            patients {
                name
                species
            }
        }
    }
`;

export interface CheckVetspireClientQueryVariables {
    vetspireId: string;
}

export interface CheckVetspireClientQueryResult {
    checkVetspireClient: {
        id: string;
    };
}

export const checkVetspireClient = gql`
    query checkVetspireClient($vetspireId: VetspireId!) {
        checkVetspireClient(vetspireId: $vetspireId) {
            id
        }
    }
`;

export interface UKGEmployeesQueryVariables {
    locationId: string;
    search: string;
    skip: number;
    limit: number;
}

export interface UKGEmployeesQueryResult {
    ukgEmployees: ReadonlyArray<UKGEmployee>;
}

export const ukgEmployees = gql`
    query ukgEmployees(
        $locationId: InternalId
        $search: String
        $skip: Int
        $limit: Int
    ) {
        ukgEmployees(
            locationId: $locationId
            search: $search
            skip: $skip
            limit: $limit
        ) {
            ...UKGEmployeeFragment
        }
    }
    ${UKGEmployeeFragment}
`;

export interface UKGProPersonsQueryResult {
    ukgProPersons: readonly UKGProPerson[];
}

export const ukgProPersons = gql`
    query ukgProPersons {
        ukgProPersons {
            _id
            personNumber
            employmentStatus
            firstName
            lastName
            email
        }
    }
`;

export interface ProviderWithPaymentMonthsListQueryVariables {
    startMonth: string;
    endMonth: string;
}

export interface ProviderWithPaymentMonthsListQueryResult {
    providerWithPaymentMonths: {
        providerWithPaymentMonths: ProviderWithPaymentMonths[];
        total: number;
    };
}

export const providerWithPaymentMonthsListQuery = gql`
    query providerWithPaymentMonths($startMonth: String!, $endMonth: String!) {
        providerWithPaymentMonths(
            startMonth: $startMonth
            endMonth: $endMonth
        ) {
            providerWithPaymentMonths {
                provider {
                    id
                    firstName
                    lastName
                }
                currentUKGSalary
                paymentMonths {
                    _id
                    vetspireProviderId
                    month
                    vetspireLocationId
                    baseSalary
                    providerSxReferralRate
                    providerPayRate
                    providerSxReferralRate
                    targetReductionRate
                    applyTargetReductionRateToSalary
                    applyTargetReductionRateToPTODaysCredit
                    applyTargetReductionRateToAdminDaysCredit
                    applyTargetReductionRateToSickDaysCredit
                    ptoDaysCount
                    adminDaysCount
                    sickDaysCount
                    extraShiftDays
                    criticalExtraShiftDays
                    allowReportDownload
                    netProductionAdjustmentAmount
                    netProductionAdjustmentNote
                    acceptedUKGSalary
                    netOnlinePharmacyAmount
                    grossOnlinePharmacyAmount
                }
            }
            total
        }
    }
`;

export interface ProviderPaymentMonthStatsQueryVariables {
    month: string;
}

export interface ProviderPaymentMonthStatsQueryResult {
    providerPaymentMonthStats: {
        enabledFor: number;
        total: number;
        providerPaymentMonths: ProviderPaymentMonth[];
    };
}

export const providerPaymentMonthStatsQuery = gql`
    query providerPaymentMonthStats($month: String!) {
        providerPaymentMonthStats(month: $month) {
            enabledFor
            total
            providerPaymentMonths {
                vetspireProviderId
            }
        }
    }
`;

export const locationsWithVetspireIdQuery = gql`
    query locations($includeClosed: Boolean = false) {
        locations(includeClosed: $includeClosed) {
            _id
            name
            vetspireId
            humanityId
            ukgCostCenterId
            ukgProLocationId
            showPublically
            placeholderShiftsEnabled
            placeholderShiftBlockOffTemplateId
        }
    }
`;

export const regionsQuery = gql`
    query getRegions {
        getRegions {
            _id
            name
            locationIds
        }
    }
`;

export const marketsQuery = gql`
    query markets {
        markets {
            _id
            name
            regionIds
        }
    }
`;

export const providerPaymentMonthReportQuery = gql`
    query providerPaymentMonthReport(
        $vetspireProviderId: ID!
        $month: String!
    ) {
        providerPaymentMonthReport(
            vetspireProviderId: $vetspireProviderId
            month: $month
        ) {
            fileName
            base64Content
        }
    }
`;

export const providerPaymentMonthSheetQuery = gql`
    query providerPaymentMonthSheet($vetspireProviderId: ID!, $month: String!) {
        providerPaymentMonthSheet(
            vetspireProviderId: $vetspireProviderId
            month: $month
        ) {
            fileName
            base64Content
        }
    }
`;

export const surgeryReferralsRawDataSheetQuery = gql`
    query surgeryReferralsRawDataSheet {
        surgeryReferralsRawDataSheet {
            fileName
            base64Content
        }
    }
`;
