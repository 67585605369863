// eslint-disable-next-line import/no-cycle
import { VetspireEncounter } from '../encounters';
import { HomebaseEmployee } from '../homebase';
import { HumanityEmployee } from '../humanity';
import { UKGEmployee } from '../ukg';
import { Projection } from '../index';
import { GraphQLUKGProPerson } from '../ukgPro';
// eslint-disable-next-line import/no-cycle
import { VetspireData } from '../vetspire';

/**
 * collection: `vetspire.providerRoles`
 *
 * Vetspire: https://developer.vetspire.com/object/ProviderRole
 */
export interface ProviderRole {
    /** job codes */
    jobCodes?: ReadonlyArray<string>;
    /** name */
    name?: string;
    /** number of users */
    numberOfUsers?: number;
    /** permissions */
    permissions?: ReadonlyArray<string>;
}

/**
 * collection: `vetspire.providerRoles`
 *
 * Vetspire: https://developer.vetspire.com/object/ProviderRole
 */
export interface VetspireProviderRole extends ProviderRole {
    /** id */
    id: string;
}

/**
 * collection: `vetspire.providers`
 *
 * Vetspire: https://developer.vetspire.com/object/Provider
 */
interface ProviderBase {
    /** email */
    email: string;
    /** (lastName) */
    familyName: string;
    /** givenName (firstName) */
    givenName: string;
    /** name (full name with title/suffix) */
    name: string;
    /** insertedAt */
    insertedAt: string;
    /** isActive */
    isActive: boolean;
    /** isCallCenter */
    isCallCenter: boolean;
    /** isGroomer */
    isGroomer: boolean;
    /** isOrgAdmin */
    isOrgAdmin: boolean;
    /** isRelief */
    isRelief: boolean;
    /** isSsoEnabled */
    isSsoEnabled: boolean;
    /** isVeterinarian */
    isVeterinarian: boolean;
    /** enabled locations for this user */
    locations: Array<string>;
    /** username in Vetspire */
    username: string;
    /** phoneNumber */
    phoneNumber?: string;
    /** normalized `phoneNumber` */
    intlPhoneNumber?: string;
    /** daily surgery limit */
    dailySurgeryLimit?: number;
    /** historicalId */
    historicalId?: string;
    /** missingVetLicense */
    missingVetLicense?: boolean;
    /** payrollId */
    payrollId?: string;
    /** position */
    position?: number;
    /** profileImageUrl */
    profileImageUrl?: string;
    /** provider role */
    providerRole?: { id: string; name: string };
    /** providerRoleId */
    providerRoleId?: string;
    /** scheduleTemplate */
    scheduleTemplate?: ReadonlyArray<string>;
    /** shortBio */
    shortBio?: string;
    /** suffix */
    suffix?: string;
    /** updatedAt */
    updatedAt?: string;
    /** verified */
    verified?: boolean;
    /** vetsourcePin */
    vetsourcePin?: string;
    defaultLocationId?: string | null;
}

export enum ProviderRightKey {
    vetspireExtension_tasks = 'vetspireExtension_tasks',
    vetspireExtension_surgeryForms = 'vetspireExtension_surgeryForms',
    vetspireExtension_encounters = 'vetspireExtension_encounters',
    vetspireExtension_staffOptimizationUKG = 'vetspireExtension_staffOptimizationUKG',
    vetspireExtension_creditCards = 'vetspireExtension_creditCards',
    vetspireExtension_providerPayment = 'vetspireExtension_providerPayment',
    vetspireExtension_gainsharing = 'vetspireExtension_gainsharing',
    vetspireExtension_chargeNoShowFees = 'vetspireExtension_chargeNoShowFees',
    vetspireExtension_apptLog = 'vetspireExtension_apptLog',
    vetspireExtension_bonusly = 'vetspireExtension_bonusly',
    vetspireExtension_infoPdf = 'vetspireExtension_infoPdf',
    vetspireExtension_surgeryCalendar = 'vetspireExtension_surgeryCalendar',
    vetspireExtension_surgeryReferrals = 'vetspireExtension_surgeryReferrals',
    vetspireExtension_externalReferrals = 'vetspireExtension_externalReferrals',
    vetspireExtension_medicalMishaps = 'vetspireExtension_medicalMishaps',
    vetspireExtension_telehealth = 'vetspireExtension_telehealth',
    vetspireExtension_suki = 'vetspireExtension_suki',
    vetspireExtension_texting = 'vetspireExtension_texting',
    vetspireExtension_patientPlans = 'vetspireExtension_patientPlans',
    vetspireExtension_travelCertificates = 'vetspireExtension_travelCertificates',
    vetspireExtension_intradayCapacity = 'vetspireExtension_intradayCapacity',
}

export enum ProviderRightValue {
    admin = 'admin',
    disabled = 'disabled',
    enabled = 'enabled',
    enabled_allRecords = 'enabled_allRecords',
    enabled_ownRecords = 'enabled_ownRecords',
    inherited = 'inherited',
}

export const PROVIDER_RIGHT_VALUE = Object.freeze(ProviderRightValue);

export const PROVIDER_RIGHT_KEY = Object.freeze(ProviderRightKey);

export const INDIVIDUAL_PROVIDER_RIGHT_KEYS = [
    'vetspireExtension_suki',
    'vetspireExtension_travelCertificates',
    'vetspireExtension_texting',
    'vetspireExtension_intradayCapacity',
] as const;

export type IndividualProviderRightKey =
    (typeof INDIVIDUAL_PROVIDER_RIGHT_KEYS)[number];

export type IndividualProviderRights = {
    [K in IndividualProviderRightKey]: ProviderRightValue;
};

export const defaultIndividualProviderRights: IndividualProviderRights = {
    vetspireExtension_suki: ProviderRightValue.disabled,
    vetspireExtension_travelCertificates: ProviderRightValue.disabled,
    vetspireExtension_texting: ProviderRightValue.disabled,
    vetspireExtension_intradayCapacity: ProviderRightValue.disabled,
};

export type ProviderRights = {
    vetspireExtension_tasks:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
    vetspireExtension_surgeryForms:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_encounters:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
    vetspireExtension_staffOptimizationUKG:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
    vetspireExtension_creditCards:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_providerPayment:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
    vetspireExtension_gainsharing:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_chargeNoShowFees:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_apptLog:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_bonusly:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_infoPdf:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_surgeryCalendar:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_surgeryReferrals:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
    vetspireExtension_externalReferrals:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_medicalMishaps:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_telehealth:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_suki:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_texting:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_patientPlans:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_travelCertificates:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled;
    vetspireExtension_intradayCapacity:
        | ProviderRightValue.disabled
        | ProviderRightValue.enabled_allRecords
        | ProviderRightValue.enabled_ownRecords;
};

export const defaultProviderRights: ProviderRights = {
    vetspireExtension_tasks: ProviderRightValue.disabled,
    vetspireExtension_surgeryForms: ProviderRightValue.disabled,
    vetspireExtension_encounters: ProviderRightValue.disabled,
    vetspireExtension_staffOptimizationUKG: ProviderRightValue.disabled,
    vetspireExtension_creditCards: ProviderRightValue.disabled,
    vetspireExtension_providerPayment: ProviderRightValue.disabled,
    vetspireExtension_gainsharing: ProviderRightValue.disabled,
    vetspireExtension_chargeNoShowFees: ProviderRightValue.disabled,
    vetspireExtension_apptLog: ProviderRightValue.disabled,
    vetspireExtension_bonusly: ProviderRightValue.disabled,
    vetspireExtension_infoPdf: ProviderRightValue.disabled,
    vetspireExtension_surgeryCalendar: ProviderRightValue.disabled,
    vetspireExtension_surgeryReferrals: ProviderRightValue.disabled,
    vetspireExtension_externalReferrals: ProviderRightValue.disabled,
    vetspireExtension_medicalMishaps: ProviderRightValue.disabled,
    vetspireExtension_telehealth: ProviderRightValue.disabled,
    vetspireExtension_suki: ProviderRightValue.disabled,
    vetspireExtension_texting: ProviderRightValue.disabled,
    vetspireExtension_patientPlans: ProviderRightValue.disabled,
    vetspireExtension_travelCertificates: ProviderRightValue.disabled,
    vetspireExtension_intradayCapacity: ProviderRightValue.disabled,
};
/**
 * collection: `vetspire.providerRoles`
 *
 * Vetspire: https://developer.vetspire.com/object/ProviderRole
 */
export interface DbProviderRole extends ProviderRole {
    _id: string;
    rights: ProviderRights;
}

/**
 * collection: `vetspire.providers`
 *
 * Vetspire: https://developer.vetspire.com/object/Provider
 */
export interface Provider extends ProviderBase {
    /** alphanumeric id (e.g. "ppJ3FgGDPiBWWG4z5") */
    _id: string;
    /** link data to Vetspire */
    _vetspire?: VetspireData | null;
    /** name */
    name: string;
}

/**
 * collection: `vetspire.providers`
 *
 * Vetspire: https://developer.vetspire.com/object/Provider
 */
export interface VetspireProvider extends ProviderBase {
    id: string;
}

/**
 * collection: `vetspire.providers`
 *
 * Vetspire: https://developer.vetspire.com/object/Provider
 */
export interface DbProvider {
    /** alphanumeric id (e.g. "ppJ3FgGDPiBWWG4z5") */
    _id?: string;
    /** Vetspire: `givenName` */
    firstName: string;
    /** Vetspire: `familyName` */
    lastName: string;
    /** Vetspire: `isActive` */
    isActive: boolean;
    /** email */
    email?: string | null;
    /** username in Vetspire */
    username?: string | null;
    /** combined firstName and lastName with title (e.g. "Dr. Clara Oswald") */
    name: string | null;
    /** @obsolete - homebase has been replaced by humanity */
    homebaseId?: string | null;
    /** the external reference to humanity */
    humanityId?: string | null;
    /** the external reference to ukg */
    ukgId?: string | null;
    /** payrollId is used to link the provider with the UKG Pro person */
    payrollId?: string;
    /** the default location of the provider */
    defaultLocationId?: string | null;
    /** imported from Vetspire */
    title?: string | null;
    /** assigned locations of provider */
    locationIds: string[];
    /** assigned regions this user has access to */
    regionIds: string[];
    /** the PIN for the Pro-Sal page in the Vetspire Extension */
    paymentPin?: string | null;
    /** employee has joined Bond Vet at... (format: `YYYY-MM-DD`) */
    employmentStartDate?: string | null;
    /** employee has left Bond Vet at... (format: `YYYY-MM-DD`) */
    employmentEndDate?: string | null;
    /** e.g. 200 if not set -> working days will be picked from ProviderWorkingDays */
    workingDays?: number;
    /** employee can do surgeries */
    canDoSurgeries?: boolean;
    /** whether to automatically generate provider payment settings each month for this provider */
    automateProviderPaymentMonths?: boolean | null;
    /** employee is veterinarian */
    isVeterinarian?: boolean;
    /** rights that are manageable by user */
    individualRights?: IndividualProviderRights;
    /** isRelief */
    isRelief: boolean;
}

export const VETSPIRE_PROVIDERS_COLLECTION_NAME = 'vetspire.providers';

export interface VetspireProviderInput {
    paymentPin?: string | null;
    employmentStartDate?: string | null;
    employmentEndDate?: string | null;
    workingDays?: number | null;
    canDoSurgeries?: boolean;
    automateProviderPaymentMonths?: boolean | null;
}

/**
 * collection: `vetspire.providers`
 *
 * Vetspire: https://developer.vetspire.com/object/Provider
 */
export type DbVetspireProvider = Omit<DbProvider, '_id'> & {
    /** numeric-as-string id in Vetspire (e.g. "1234") */
    _id: string;
    /** @obsolete - homebase has been replaced by humanity */
    _homebase?: { id?: string | null } | null;
    /** the external reference to humanity */
    _humanity?: { id?: string | null } | null;
    /** the external reference to ukg */
    _ukg?: { id?: string | null } | null;
    providerRole?: VetspireProviderRole | null;
};

export type VetspireProviderWithRights = DbVetspireProvider & {
    rights: ProviderRights;
};

export type GraphQLVetspireProvider = DbProvider & {
    id: string;
    homebase?: HomebaseEmployee | null;
    humanity?: HumanityEmployee | null;
    ukg?: UKGEmployee | null;
    ukgPro?: GraphQLUKGProPerson | null;
    /** effective rights, cumulated rights of provider role and provider.individualRights */
    rights?: ProviderRights;
};

export const PROVIDER_PROJECTION_WITHOUT_PAYMENT_PIN: Projection = {
    _id: 1,
    isActive: 1,
    firstName: 1,
    lastName: 1,
    email: 1,
    username: 1,
    name: 1,
    defaultLocationId: 1,
    '_homebase.id': 1,
    '_humanity.id': 1,
    '_ukg.id': 1,
    payrollId: 1,
    individualRights: 1,
    employmentStartDate: 1,
    employmentEndDate: 1,
    workingDays: 1,
    canDoSurgeries: 1,
    automateProviderPaymentMonths: 1,
    providerRole: 1,
};

export const PROVIDER_PROJECTION: Projection = {
    ...PROVIDER_PROJECTION_WITHOUT_PAYMENT_PIN,
    paymentPin: 1,
};

/**
 * collection: `provider.paymentMonths`
 */
export interface ProviderPaymentLine {
    /** date and time of the order item */
    datetime: Date;
    /** numeric-as-string foreign key to {@link Location}`._vetspire.id` (e.g. "1234") */
    locationId: string;
    /** imported from Vetspire OrderItem */
    orderId: string;
    /** imported from Vetspire OrderItem */
    orderItemId: string;
    /** product info */
    product?: {
        /** numeric-as-string foreign key to Vetspire products (e.g. "1234") */
        id?: string;
        /** name of the product */
        name?: string;
    };
    /** product type info */
    productType?: {
        /** numeric-as-string foreign key to `vetspire.productsTypes` (e.g. "1234") */
        id?: string;
        /** name of the product type */
        name?: string;
        /** provider credit rate of the product type */
        providerCreditRate?: number;
    };
    /** provider info */
    provider?: {
        /** numeric-as-string foreign key to {@link DbVetspireProvider} (e.g. "1234") */
        id?: string;
        /** copy of {@link DbVetspireProvider}`.name` */
        name?: string;
    };
    /** patient info */
    patient?: {
        /** numeric-as-string foreign key to {@link Animal}`._vetspire.id` (e.g. "1234") */
        id?: string;
        /** copy of {@link Animal}`.name` */
        name?: string;
    };
    /** imported from Vetspire OrderItem */
    name: string;
    /** imported from Vetspire OrderItem */
    total: number;
    /** imported from Vetspire OrderItem */
    credited: boolean;
    /** imported from Vetspire OrderItem */
    refunded: boolean;
    /** imported from Vetspire OrderItem */
    refundedAt: Date;
    /** imported from Vetspire OrderItem */
    returned: boolean;
    /** imported from Vetspire OrderItem */
    returnedAt: Date;
    /** imported from Vetspire OrderItem */
    notRendered: boolean;
    /** productType.providerCreditRate or defaultCreditRate */
    providerCreditRate: number;
    /** nurseCredit (global settings) e.g. 0.25 */
    nurseCreditRate?: number;
    /** total * providerCreditRate or total * providerCreditRate * nurseCreditRate */
    providerCreditTotal: number;
}

/**
 * collection: `provider.paymentMonths`
 */
export interface ExtraShiftDayCalculation {
    /** day of month */
    extraShiftDay: number;
    /** payment lines of that day */
    paymentLines: ProviderPaymentLine[];
    /** sum of all providerPaymentLines.providerCreditTotal */
    providerCreditTotal: number;
    /** providerCreditTotal * providerPayRate */
    earnings: number;
    /** max(1200, earnings of that day) */
    bonus: number;
}

/**
 * results of a payment calculation, stored at `provider.paymentMonths.paymentCalculation`.
 * Beside the results itself, all base data for the calculation is included as well.
 */
export interface PaymentCalculation {
    /** created at... */
    createdAt: Date;
    /** copy of global settings at the time of the calculation */
    providerPaymentSettings?: ProviderPaymentSetting;
    /** copy of provider/month specific settings at the time of the calculation */
    providerPaymentMonth?: ProviderPaymentMonth;

    /** copy of {@link DbVetspireProvider}`.employmentStartDate` */
    employmentStartDate?: string;
    /** copy of {@link DbVetspireProvider}`.employmentEndDate` */
    employmentEndDate?: string;
    /** copy of {@link ProviderYearlyPayRate}`.payRate`, e.g. 0.19 */
    providerPayRate?: number;

    /** copy of {@link ProviderPaymentMonth}`.providerSxReferralRate`, e.g. 0.19 */
    providerSxReferralRate?: number;
    /** copy of {@link ProviderPaymentMonth}`.targetReductionRate`, e.g. 0.25 */
    targetReductionRate?: number;
    /** copy of {@link ProviderPaymentMonth}`.applyTargetReductionRateToSalary` */
    applyTargetReductionRateToSalary?: boolean;
    /** copy of {@link ProviderPaymentMonth}`.applyTargetReductionRateToPTODaysCredit` */
    applyTargetReductionRateToPTODaysCredit?: boolean;
    /** copy of {@link ProviderPaymentMonth}`.applyTargetReductionRateToAdminDaysCredit` */
    applyTargetReductionRateToAdminDaysCredit?: boolean;
    /** copy of {@link ProviderPaymentMonth}`.applyTargetReductionRateToSickDaysCredit` */
    applyTargetReductionRateToSickDaysCredit?: boolean;

    /** number of providers with the same home location at the time of the calculation */
    locationProviderCount?: number;
    /** copy of {@link Location}.`nurseVetspireProviderId` */
    nurseVetspireProviderId?: string;

    /** baseSalary reduced by targetReductionRate */
    reducedBaseSalary?: number;

    /** monthly_salary / pay_rate */
    monthlyGoal?: number;

    /** ptoDaysCount * (12 * monthly_salary / expected_working_days / pay_rate) */
    ptoDaysCredit?: number;
    /** adminDaysCount * (12 * monthly_salary / expected_working_days / pay_rate) */
    adminDaysCredit?: number;
    /** sickDaysCount * (12 * monthly_salary / expected_working_days / pay_rate) */
    sickDaysCredit?: number;

    /** fetched from vetspire.shifts (only needed for visualization) */
    workingDays?: number[];
    /** expected working days for current year, e.g. 200 */
    expectedWorkingDays?: number;

    /** all payment lines of the provider */
    providerPaymentLines?: ProviderPaymentLine[];
    /** sum of all providerPaymentLines.providerCreditTotal */
    providerCreditTotal?: number;
    /** all payment lines of the nurse provider */
    nursePaymentLines?: ProviderPaymentLine[];
    /** sum of all nursePaymentLines.providerCreditTotal */
    nurseCreditTotalOverall?: number;
    /** nurseCreditTotalOverall / locationProviderCount */
    nurseCreditTotal?: number;
    /** all payment lines of linked surgery referrals */
    sxReferralPaymentLines?: ProviderPaymentLine[];
    /** sum of all providerPaymentLines.providerCreditTotal without rate */
    sxReferralCreditTotalWithoutRate?: number;
    /** sum of all providerPaymentLines.providerCreditTotal */
    sxReferralCreditTotal?: number;
    /** Net credit added from online pharmacy activity */
    netOnlinePharmacyAmount?: number;
    /** calculation data for extra shifts */
    extraShiftDaysCalculation?: ExtraShiftDayCalculation[];
    /** calculation data for critical extra shifts */
    criticalExtraShiftDaysCalculation?: ExtraShiftDayCalculation[];

    /** ptoCredit + adminDaysCredit + sickDaysCredit + providerCreditTotal + nurseCreditTotal */
    totalProduction?: number;
    /** totalProduction * providerPayRate */
    totalEarnings?: number;
    /** max(0, totalEarnings - baseSalary) */
    monthlyBonus?: number;
    /** sum of all extra shift day bonuses */
    extraShiftDayBonusSum?: number;
    /** sum of all critical extra shift day bonuses */
    criticalExtraShiftDayBonusSum?: number;
    /** monthlyBonus + extraShiftDayBonusSum + criticalExtraShiftDayBonusSum */
    totalBonus?: number;

    /** the actual pay out, baseSalary + totalBonus */
    payOut?: number;

    /** any error that occured during the calculation, null if no error occured */
    error?: string | null;
}

/**
 * collection: `provider.paymentMonths`
 */
export interface ProviderPaymentMonth {
    /** vetspireProviderId + '|' + month, e.g. '8684|2021-04' */
    _id: string;
    /** numeric-as-string foreign key to {@link VetspireProvider} (e.g. "8686") */
    vetspireProviderId: string;
    /** format: YYYY-MM (e.g. '2021-04') */
    month: string;
    /**
     * home location of the provider, e.g. '414', if not set -> no nurse credit will be added
     * numeric-as-string foreign key to {@link Location}`._vetspire.id` (e.g. "8686")
     */
    vetspireLocationId?: string;
    /** e.g. 12.000 */
    baseSalary: number;
    /** e.g. 0.19 if not set -> pay rate is picked from ProviderYearlyPayRate */
    providerPayRate?: number;
    /** e.g. 0.25 */
    providerSxReferralRate?: number;
    /** reduce target by rate for doctors with admin/HQ/recruiting duties */
    targetReductionRate?: number;
    /** if true - lower the target based on formula. This also lowers the amount of money they get from
     * PTO and admin credit since the salary is baked into that formula */
    applyTargetReductionRateToSalary?: boolean;
    /** if true - apply to PTO days credit which mean the % would be DOUBLE applied
     * if salary reduction was toggled since the PTO days credit formula uses salary */
    applyTargetReductionRateToPTODaysCredit?: boolean;
    /** if true - apply to admin days credit (similar to `applyTargetReductionRateToPTODaysCredit`) */
    applyTargetReductionRateToAdminDaysCredit?: boolean;
    /** if true - apply to sick credit (similar to `applyTargetReductionRateToPTODaysCredit`) */
    applyTargetReductionRateToSickDaysCredit?: boolean;
    /** list of extra shifts, e.g. 1,5,22,31 */
    extraShiftDays: number[];
    /** list of critical extra shifts, e.g. 1,5,22,31 */
    criticalExtraShiftDays: number[];
    /** number of PTO days (decimal!) */
    ptoDaysCount: number;
    /** number of admin days (decimal!) */
    adminDaysCount: number;
    /** number of sick days (decimal!) */
    sickDaysCount: number;
    /** enable download button in Vetspire extension */
    allowReportDownload?: boolean;
    /** updated at... */
    updatedAt: Date;

    /** result of last payment calculation */
    paymentCalculation?: PaymentCalculation | null;
    /** Manual amount set in team app to add to totalProduction */
    netProductionAdjustmentAmount?: number;
    /** Manual reason set in team app to add to totalProduction */
    netProductionAdjustmentNote?: string;
    /** Salary from UKG that user has manually accepted a mismatch for */
    acceptedUKGSalary?: number | undefined;
    /** Manually entered - gross amount earned from online pharmacy */
    grossOnlinePharmacyAmount?: number | undefined;
    /** Manually entered - net amount earned from online pharmacy */
    netOnlinePharmacyAmount?: number | undefined;
}

export interface ProviderPaymentMonthInput {
    vetspireLocationId?: string;
    baseSalary?: number;
    providerPayRate?: number;
    providerSxReferralRate?: number;
    targetReductionRate?: number;
    applyTargetReductionRateToSalary?: boolean;
    applyTargetReductionRateToPTODaysCredit?: boolean;
    applyTargetReductionRateToAdminDaysCredit?: boolean;
    applyTargetReductionRateToSickDaysCredit?: boolean;
    ptoDaysCount: number;
    adminDaysCount: number;
    sickDaysCount: number;
    extraShiftDays: number[];
    criticalExtraShiftDays: number[];
    expectedWorkingDays?: number;
    allowReportDownload?: boolean;
    netProductionAdjustmentAmount?: number;
    netProductionAdjustmentNote?: string | undefined;
    netOnlinePharmacyAmount?: number | undefined;
    grossOnlinePharmacyAmount?: number | undefined;
    acceptedUKGSalary?: number | undefined;
}

export interface ProviderWithPaymentMonths {
    provider: GraphQLVetspireProvider;
    currentUKGSalary: number | undefined;
    paymentMonths: ProviderPaymentMonth[];
}

/**
 * collection: `provider.yearlyPayRates`
 */
export interface ProviderYearlyPayRate {
    /** === affiliationYear */
    _id: string;
    /** employee affiliation */
    affiliationYear: number;
    /** %-value */
    payRate: number;
}

export type ProviderYearlyPayRateInput = Omit<ProviderYearlyPayRate, '_id'>;

// collection: provider.workingDays
// the reason for this collection is that each year can have a different amount of working days
// which is not necessarily the same for each year.

export interface ProviderWorkingDay {
    _id: string; // === year
    year: number; // e.g. 2021
    workingDays: number; // e.g. 200
}

export type ProviderWorkingDayInput = Omit<ProviderWorkingDay, '_id'>;

/**
 * collection: `provider.paymentSettings`
 *
 * global settings for payment calculation - only one document expected
 */
export interface ProviderPaymentSetting {
    /** always 'settings' - only one document should be in this collection */
    _id: 'settings';
    /** e.g. 0.25 */
    nurseCredit: number;
    /** e.g. 0.25 */
    surgeryReferralRateDefault: number;
    /** e.g. 100 */
    lineItemWithoutProductTypeCredit: number;
    /** e.g. 1200 */
    extraShiftBonus: number;
    /** e.g. 1500 */
    criticalExtraShiftBonus: number;
    /** list of job title IDs to automate prosal settings for each month */
    ukgJobTitleIds: string[];
    /** list of productIds for which the provider shall not get any credit
     * e.g. ["202053","202052"]
     */
    zeroCreditProductIds: string[];
    /** list of productIds for which the provider shall get full credit even
     * if a coupon is applied
     * e.g. ["202053","202052"]
     */
    fullCreditProductIds: string[];
    /**
     * on: enable automatedProviderCorrection
     * off: disable automatedProviderCorrection
     * dryRun: log will be written but changes will not be applied
     */
    automatedProviderCorrection: 'off' | 'dryRun' | 'on';
    /**
     * on: enable automatedProviderCorrection_updateEncounters
     * off: disable automatedProviderCorrection_updateEncounters
     */
    automatedProviderCorrection_updateEncounters: 'off' | 'on';
    /**
     * on: enable automatedPaymentCalculation
     * off: disable automatedPaymentCalculation
     */
    automatedPaymentCalculation: 'off' | 'on';
    /** Date (YYYY-MM) of the first report to display the online pharmacy line item */
    firstOnlinePharmacyCreditMonth: string;
    /** Date (YYYY-MM) of the first report to display critical extra shift days */
    firstCriticalExtraShiftDaysMonth: string;
    /** Date (YYYY-MM) of the first report exclude admin and PTO credit lines */
    firstNoAdminPtoSickMonth: string;
}

export type ProviderPaymentSettingInput = Omit<ProviderPaymentSetting, '_id'>;

// collection: provider.paymentCorrections
export interface ProviderPaymentCorrection {
    _id: string;
    createdAt: Date;
    collection: string; // the collection at vetspire - 'encounter', 'order' or 'orderItem'
    field: string; // e.g.
    recordId: string; // the vetspire record ID
    oldValue: string;
    newValue: string;
    client?: {
        id: string;
        name: string;
    };
    patients?: {
        id: string;
        name: string;
    }[];
    encounters?: VetspireEncounter[];
    encounterUrls?: string[];
    invoiceUrl?: string | null;
    action: string;
    status: string;
}

/**
 * collection: `provider.paymentDays`
 */
export interface ProviderPaymentDay {
    /** vetspireProviderId + '|' + vetspireLocationId + '|' + day, e.g. '8684|21|2021-04-01' */
    _id: string;
    /** numeric-as-string foreign key to {@link VetspireProvider} (e.g. "8686") */
    vetspireProviderId: string;
    /**
     * home location of the provider for current month
     * numeric-as-string foreign key to {@link Location}`._vetspire.id` (e.g. "8686")
     * */
    vetspireLocationId: string;
    /** format: YYYY-MM-DD (e.g. '2021-04-01') */
    day: string;
    /** format: YYYY-MM (e.g. '2021-04') */
    month: string;
    /**
     * number of providers at the home location of the current provider at the current day
     */
    locationProviderCount: number;
    /** copy of {@link ProviderYearlyPayRate}`.payRate`, e.g. 0.19 */
    providerPayRate?: number;
    /** e.g. 12.000 */
    baseSalary: number;
    /** sum of all providerPaymentLines.total of current day */
    providerProductionTotal?: number;
    /** sum of all providerPaymentLines.providerCreditTotal of current day */
    providerCreditTotal?: number;
    /** sum of all nursePaymentLines.total of current day */
    nurseProductionTotal?: number;
    /** sum of all nursePaymentLines.nurseCreditTotal of current day */
    nurseCreditTotal?: number;
}

export type UpdateVetspireProviderInput = {
    automateProviderPaymentMonths?: boolean | null;
    canDoSurgeries?: boolean;
    isRelief?: boolean;
    defaultLocationId?: string | null;
    employmentEndDate?: string;
    employmentStartDate?: string;
    paymentPin?: string;
    // if individual right is null or undefined, the right will be inherited from the role.
    individualRights?: DbProvider['individualRights'];
    ukgEmployeeId?: string | undefined;
    payrollId?: string | undefined;
    workingDays?: number;
};
